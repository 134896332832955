import { PageProps } from "gatsby";
import React from "react";

import { Staff } from "../components/pages/Staff";

import { snProps } from "../js/utils";

const StaffPage = (props: PageProps) => <Staff {...{ ...snProps, ...props }} />;

export default StaffPage;

import React from "react";
import { Link } from "gatsby";
import Layout from "../parts/Layout";
import { NextPageLink } from "../parts/NextPageLink";
import { PageComponentProps } from "../../types/types";

// import ChromaticScaleDn from "../../images/svg-dn/Clairnote-chromatic-scale.svg";
// import ChromaticScaleSn from "../../images/svg-sn/Clairnote-chromatic-scale.svg";

// @ts-ignore
import ChromaticScaleAscendingDn from "../../images/svg-dn/Clairnote-chromatic-scale-ascending-sharps.svg";
// @ts-ignore
import ChromaticScaleAscendingSn from "../../images/svg-sn/Clairnote-chromatic-scale-ascending-sharps.svg";

// @ts-ignore
import ChromaticScaleDescendingDn from "../../images/svg-dn/Clairnote-chromatic-scale-descending-flats.svg";
// @ts-ignore
import ChromaticScaleDescendingSn from "../../images/svg-sn/Clairnote-chromatic-scale-descending-flats.svg";

// @ts-ignore
import OctavesCMajor2Dn from "../../images/svg-dn/Clairnote-octaves-C-major-2.svg";
// @ts-ignore
import OctavesCMajor2Sn from "../../images/svg-sn/Clairnote-octaves-C-major-2.svg";

// @ts-ignore
import StaffVerticalCompressionDn from "../../images/svg-dn/Clairnote-staff-vertical-compression.svg";
// @ts-ignore
import StaffVerticalCompressionSn from "../../images/svg-sn/Clairnote-staff-vertical-compression.svg";

// @ts-ignore
import StaffVerticalCompressionTwoDn from "../../images/svg-dn/Clairnote-staff-vertical-compression-two.svg";
// @ts-ignore
import StaffVerticalCompressionTwoSn from "../../images/svg-sn/Clairnote-staff-vertical-compression-two.svg";

// @ts-ignore
import StaffOctaveSpansDn from "../../images/svg-dn/Clairnote-staff-octave-spans.svg";
// @ts-ignore
import StaffOctaveSpansSn from "../../images/svg-sn/Clairnote-staff-octave-spans.svg";

// @ts-ignore
import SixSixWholeToneScaleDn from "../../images/svg-dn/Clairnote-66-whole-tone-scale.svg";
// @ts-ignore
import SixSixWholeToneScaleSn from "../../images/svg-sn/Clairnote-66-whole-tone-scale.svg";

import * as pageStyles from "../global-styles/page.module.css";
import * as utilStyles from "../global-styles/util.module.css";

export const Staff = (props: PageComponentProps) => {
  const { clairnoteName, lineOrSolid, spaceOrHollow, sn, dn, urlDir } = props;
  const title = "Staff";
  const description = `Discussion of ${clairnoteName}'s chromatic staff and its use of vertical compression which takes up less space on the page and makes intervals easier to read.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
          And the Representation of Pitch
        </h2>
        <div>
          <p>
            {clairnoteName} has a "chromatic" staff that gives each of the
            twelve notes of the chromatic scale a distinct vertical position on
            the staff. Notes appear on a line or in one of three positions in
            the space between the lines: "sitting" directly above a line,
            "hanging" directly below a line, or{" "}
            {dn ? <>"floating"</> : <>on a ledger line</>} half-way between two
            lines.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChromaticScaleAscendingDn : ChromaticScaleAscendingSn}
              alt={`Ascending chromatic scale on a ${clairnoteName} staff`}
            />
          </div>
          <p>
            On a chromatic staff the vertical distance between notes is always
            proportional to their difference in pitch. This helps provide a
            clear and consistent representation of the interval relationships
            between notes.{" "}
            {dn ? (
              <>The hollow and solid pattern of the noteheads</>
            ) : (
              <>The pattern of line notes and space notes</>
            )}{" "}
            (the 6-6 pitch pattern) also helps to make these interval
            relationships more apparent.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChromaticScaleDescendingDn : ChromaticScaleDescendingSn}
              alt={`Descending chromatic scale on a ${clairnoteName} staff`}
            />
          </div>

          <h2 className="title is-5" id="six-six">
            6-6 Pitch Pattern
          </h2>
          <p>
            An important aspect of {clairnoteName}'s representation of the
            twelve notes of the chromatic scale is that it provides a visual
            distinction between the two whole tone scales. (A whole tone scale
            is a series of notes evenly spaced a whole step apart.) One is a
            series of 6 {lineOrSolid} notes, and the other is a series of 6{" "}
            {spaceOrHollow} notes. This is known as a "6-6" pitch pattern.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              alt={`6-6 pitch pattern in ${clairnoteName} music notation`}
              src={dn ? SixSixWholeToneScaleDn : SixSixWholeToneScaleSn}
            />
          </div>
          <p>
            These two whole tone scales combine in a regularly alternating
            pattern to form the chromatic scale.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              alt={`Chromatic scale in ${clairnoteName} music notation`}
              src={dn ? ChromaticScaleAscendingDn : ChromaticScaleAscendingSn}
            />
          </div>
          <p>
            This regular pattern in the representation of pitch means that
            intervals, scales, and chords have a consistent appearance
            regardless of the current key or their vertical position on the
            staff. This is known as <em>isomorphism</em> (the syllables "iso"
            and "morph" meaning "same" and "shape").
          </p>
          <p>
            For example, all major scales start with three {lineOrSolid} notes,
            followed by four {spaceOrHollow} notes (or vice-versa). All minor
            thirds are a {lineOrSolid} note and a {spaceOrHollow} note, while
            major thirds are two {lineOrSolid} notes or two {spaceOrHollow}{" "}
            notes. What you see always corresponds with what you hear.{" "}
            {dn &&
              "This is why " +
                clairnoteName +
                " uses hollow and solid noteheads to help indicate pitch and " +
                "interval relationships (through the 6-6 pitch pattern), " +
                "rather than using them for "}
            {dn && (
              <Link title="Rhythm Notation" to={urlDir + "rhythm-notation/"}>
                duration (rhythm)
              </Link>
            )}
            {dn && "."}
          </p>
          <p>
            The benefits of the 6-6 pitch pattern can be understood by analogy
            with the distinction between odd and even numbers. Odd and even
            numbers help with counting and determining the relationship between
            two numbers. In a similar way, a 6-6 pitch pattern makes the
            interval between two notes easier to perceive. And that makes it
            easier to see and understand the contours of scales and melodies and
            the patterns that make up chords and harmonies.
          </p>
          <p>
            See{" "}
            <Link title="Scales" to={urlDir + "scales/"}>
              Scales
            </Link>{" "}
            and{" "}
            <Link title="Intervals" to={urlDir + "intervals/"}>
              Intervals
            </Link>{" "}
            for illustrations of the consistency that results from{" "}
            {clairnoteName}'s 6-6 pitch pattern.
          </p>
          <h2 className="title is-5">
            The Staff's Line Pattern Repeats With Each Octave
          </h2>
          <p>
            The groups of two lines that make up the staff always represent the
            note E and the note G-sharp (or A-flat). The repeating line pattern
            corresponds with the repetition of the octave. That means every
            octave has the same basic representation on the staff. Notes an
            octave apart always fall on "the same" line or space, making it easy
            to identify notes. What looks like a "missing" middle line is
            actually an "internal" ledger line that represents C.{" "}
            {sn && (
              <>
                Additional "secondary" internal ledger lines represent D,
                F-sharp (or G-flat), and A-sharp (or B-flat).
              </>
            )}
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? OctavesCMajor2Dn : OctavesCMajor2Sn}
              alt={`Octaves in ${clairnoteName} music notation`}
            />
          </div>
          <h2 className="title is-5">Staves Spanning More Than Two Octaves</h2>
          <p>
            The default {clairnoteName} staff spans two octaves (from the first
            C ledger line below the staff to the first C ledger line above it).
            Staves with greater pitch ranges can be used as needed, depending on
            the range of the music and/or the instrument. A one octave staff
            could even be used, for example when teaching younger children.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? StaffOctaveSpansDn : StaffOctaveSpansSn}
              alt={`Two, Three, and Four Octave ${clairnoteName} staff`}
            />
          </div>
          <p>
            Piano music can be written on a continuous four-octave{" "}
            {clairnoteName} staff like the one shown above, or on a "grand
            staff" with two separate staves that span two octaves each, one for
            the left hand and one for the right, as in traditional music
            notation.
          </p>
          <p>
            Music for guitar can be written on a three-octave {clairnoteName}{" "}
            staff. The notes are played as written. (Music for guitar in
            traditional notation is typically written in treble clef, but the
            notes are played an octave lower than they are written.)
          </p>
          <p>
            If the music extends above or below the staff then ledger lines are
            used as in traditional notation. To avoid having too many ledger
            lines the {clairnoteName} staff can be temporarily extended up or
            down by an octave or more.
          </p>
          <h2 className="title is-5">
            How Does Learning the {clairnoteName} Staff Compare with Learning
            the Traditional Staff?
          </h2>
          <p>
            Consider this (admittedly) overly simplistic quantitative
            comparison, that disregards key signatures, accidental signs and
            other factors. Given a single clef on a standard staff there are
            only seven staff positions per octave to learn. However, there are
            roughly thirteen staff positions to learn (five lines, four spaces,
            plus one space and one ledger line above and below the staff).
          </p>
          <p>
            On {clairnoteName}'s staff there are twelve note positions per
            octave rather than seven, but because the staff's line pattern
            repeats with each octave, those twelve positions are the only ones
            to learn. So the number of staff positions to learn is about the
            same as learning to read in one clef on the standard staff.
          </p>
          <p>
            Of course, the thirteen positions on a standard staff are{" "}
            <em>different</em> for each clef. Someone learning to play piano has
            to read treble and bass clefs at the same time, which is twenty six
            unique staff positions. (See also{" "}
            <Link title="Clefs" to={urlDir + "clefs/"}>
              Clefs
            </Link>
            .)
          </p>
          <h2 className="title is-5">
            {clairnoteName}
            's "Vertically Compressed" Chromatic Staff
          </h2>
          <p>
            One of the only disadvantages of a chromatic staff is that it takes
            up more vertical space on the page compared to a traditional
            "diatonic" staff because there are twelve notes per octave rather
            than seven. The distance between any two given notes becomes
            greater, both physically and symbolically with the number of
            intervening staff positions. This can make it harder to easily
            recognize larger intervals and tell them apart.
          </p>
          <p>
            {clairnoteName} addresses this by spacing staff lines a major third
            apart and through "vertical compression" of the staff, allowing
            vertically adjacent notes to overlap to a greater extent than they
            do on a traditional staff.{" "}
            {dn ? (
              <>
                The hollow and solid (<a href="#six-six">6-6</a>) notehead
                pattern also helps by making it easier to differentiate between
                notes.
              </>
            ) : (
              <>
                The consistent (<a href="#six-six">6-6</a>) pitch pattern of
                line notes and space notes helps by making it easier to
                differentiate between notes.
              </>
            )}
          </p>
          <p>
            The following image shows three different staves with the same
            one-octave harmonic intervals (E to E and F to F).
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? StaffVerticalCompressionDn : StaffVerticalCompressionSn}
              alt={`Vertical Compression of ${clairnoteName} Staff`}
            />
          </div>
          <p>
            On the "uncompressed" chromatic staff (far right) the adjacent notes
            overlap the same amount as on a standard staff (far left), so an
            octave spans 12/7 (or 1.714...) times as much space as an octave on
            a standard staff. (Because there are 12 staff positions per octave
            instead of 7.) On the compressed {clairnoteName} staff adjacent
            notes overlap more, resulting in an octave that's only 9/7
            (1.285...) times the size of an octave on a standard staff.
          </p>
          <p>
            In more detail, the distance between the midpoints of adjacent notes
            on the compressed {clairnoteName} staff is 3/4 of what it is on a
            standard staff. The relative size of the octave is thus 3/4 (the
            distance between adjacent notes) times 12/7 (the ratio of the number
            of notes per octave on the staff) which is 36/28 or 9/7.
          </p>
          <p>
            Here is another image that adds a hypothetical chromatic staff that
            has been compressed further (too far...) so that an octave spans the
            exact same vertical extent as on a standard staff.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                dn
                  ? StaffVerticalCompressionTwoDn
                  : StaffVerticalCompressionTwoSn
              }
              alt={`Vertical Compression of ${clairnoteName} Staff`}
            />
          </div>
          <p>
            This hypothetical staff is still physically larger than the standard
            staff even though an octave spans the same physical distance. This
            is because the {clairnoteName} staff covers a greater pitch range
            from its bottom line to its top line, from E to G-sharp (16
            semitones). The standard staff spans from E to F (13 semitones) in
            treble clef, or G to A (14 semitones) in bass clef. The image shows
            that part of the larger size of the standard {clairnoteName} staff
            is due to this greater pitch range of the {clairnoteName} staff.
            (Note that the larger range of the {clairnoteName} staff means that
            there is less of a need for ledger lines.)
          </p>
          <p>
            In short, the vertical compression of the {clairnoteName} staff
            allows it to take up less space on the page. That makes music easier
            to read, especially larger intervals. (This "vertical compression"
            approach has also been used by John Keller in his{" "}
            <a
              href="http://musicnotation.org/system/express-stave-by-john-keller/"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              Express Stave
            </a>{" "}
            system that he introduced in 2005, and by Jan Braunstein in his{" "}
            <a
              href="http://www.delos.cz/en/Chromatic_Notation.html"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              Chromatic Lyre Notation
            </a>
            . Some notation systems like{" "}
            <a
              href="http://musicnotation.org/system/twinline-notation-by-thomas-reed/"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              Twinline
            </a>{" "}
            and{" "}
            <a
              href="http://musicnotation.org/system/twinnote-by-paul-morris/"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              TwinNote
            </a>{" "}
            use different notehead shapes as another way to achieve a vertically
            compressed chromatic staff.)
          </p>
          <p>
            Next, check out how easy it is to see the interval patterns of
            scales in {clairnoteName}.
          </p>
          <NextPageLink title="Scales" to={urlDir + "scales/"} />
        </div>
      </article>
    </Layout>
  );
};
